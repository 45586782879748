<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon>
        {{
          !drawer
            ? 'mdi-menu-open'
            : this.$vuetify.rtl
            ? 'mdi-menu-right'
            : 'mdi-menu-left'
        }}
      </v-icon>

      <!-- <v-icon v-else>
        mdi-dots-vertical
      </v-icon> -->
    </v-btn>

    <!-- <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    /> -->

    <v-spacer />

    <!-- <v-autocomplete
      v-model="item"
      :items="results"
      :search-input.sync="search"
      hide-details
      hide-selected
      item-text="text"
      item-value="path"
      :label="$t('search')"
      solo
      class="search-wrapper"
      @keyup="searchResults()"
      @change="moveToResult()"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            חפש משתמשים, לקוחות ומכשירים
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:selection="{ attr, on, item }">
        <span>
          <strong>{{ item.text }}</strong>
          &nbsp;
          <small :style="`color: ${item.color};`">({{ item.type }})</small>
        </span>
      </template>

      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <span :style="`color: ${item.color};`">
            {{ item.type }}
          </span>
        </v-list-item-action>
      </template>
    </v-autocomplete> -->

    <!-- <v-autocomplete
      v-model="searchItem"
      :search-input.sync="search"
      @keyup="searchResults()"
      :items="results"
      item-text="text"
      item-value="path"
      hide-details
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            חפש משתמשים, לקוחות ומכשירים
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:selection="{ attr, on, item, selected }">
        <v-flex d-flex>
          <v-list-item-content>
            <v-list-item-title
              {{item.text}} - 1
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <small>1111 - {{item.type}}</small>
          </v-list-item-action>
        </v-flex>
      </template>

    </v-autocomplete> -->

    <!-- <v-autocomplete
      :label="$t('search')"
      v-model="searchItem"
      :items="results"
      item-text="text"
      item-value="path"
      :search-input.sync="search"
      hide-details
      clearable
      background-color="transparent"
      solo
      @keyup="searchResults()"
      autocomplete="off"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            חפש משתמשים, לקוחות ומכשירים
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="{ item }">
        <v-flex d-flex>
          <v-list-item-content>
            <v-list-item-title
              {{item}}
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <small>{{item}}</small>
          </v-list-item-action>
        </v-flex>
      </template>
    </v-autocomplete> -->
    <!-- <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
      v-model="search"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-btn class="ml-2" min-width="0" text :to="`/clients/${client.code}`">
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from 'vuetify/lib'
//
// // Utilities
// import { mapState, mapMutations } from 'vuex'
const MAX_CHARS_FOR_SEARCH = 100
const MAXIMUS_RESULTS_TO_VIEW = 10

export default {
  name: 'DashboardCoreAppBar',

  components: {
    // AppBarItem: {
    //   render (h) {
    //     return h(VHover, {
    //       scopedSlots: {
    //         default: ({ hover }) => {
    //           return h(
    //             VListItem,
    //             {
    //               attrs: this.$attrs,
    //               class: {
    //                 'black--text': !hover,
    //                 'white--text secondary elevation-12': hover,
    //               },
    //               props: {
    //                 activeClass: '',
    //                 dark: hover,
    //                 link: true,
    //                 ...this.$attrs,
    //               },
    //             },
    //             this.$slots.default,
    //           )
    //         },
    //       },
    //     })
    //   },
    // },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      "You're now friends with Andrew",
      'Another Notification',
      'Another one',
    ],
    item: '',
    search: '',
    results: [
      {
        component: 'User',
        path: '/users/nadav--switchq-co-il/details',
        text: '0523558427',
        type: "User's phone",
      },
    ],
  }),

  computed: {
    drawer () {
      return this.$store.getters['drawer/drawer']
    },
    client () {
      return this.$store.getters['clients/self']
    },
  },

  methods: {
    setDrawer (drawer) {
      this.$store.dispatch('drawer/setDrawer', drawer)
    },

    async searchResults () {
      const search = this.search.trim().toLowerCase()
      if (search && search.length <= MAX_CHARS_FOR_SEARCH) {
        const results = await this.$store.dispatch('search/search', search)
        this.results = results.slice(0, MAXIMUS_RESULTS_TO_VIEW)
      }
      console.log('finaly Results', this.results)
    },

    moveToResult () {
      this.$router.push(this.item)
    },
  },
}
</script>

<style scoped>
.search-wrapper {
  width: 500px;
  max-width: 500px;
}
</style>
